import React, {useState, useEffect, useRef, useMemo } from 'react';
import {Container,  Button,Card, CardContent,Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';
import { useAcceptJs } from 'react-acceptjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import { DEFAULTS } from '../../../../Constants/Constants'
import Heading from '../../Common/Header/Heading';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getDate, sortByDate, convertFirestoreTimestampToDate } from '../../../../util/DisplayUtils';
function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}


type shipmentOrderType = {
  [key: string]: any;
}

type adminType = {
  [key: string]: any;
}
type walletType = {
  [key: string]: any;
}
function MyCommission() {
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const shipment = useSelector((state: RootState) => state.shipmentOrder.value)
  const [response, setResponse] = React.useState<string>('');
  const [wallet, setWallet] = React.useState([]);
  const [walletAmount, setWalletAmount] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [amountValid, setAmountValid] = React.useState(false);
  const [walletRow, setWalletRow] = React.useState<walletType>();
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [walletBalance, setWalletBalance] = React.useState(0);
  const token = localStorage.getItem('admin-token');
  const [redeem, setRedeem]= React.useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  
  



 const ADMINDETAILS: adminType | null = useMemo(() => {
  const adminDetailsString = localStorage.getItem('admin-details');
  return adminDetailsString ? JSON.parse(adminDetailsString) : null;
}, []);

 const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
  const inputAmount = event.target.value;
      if (/^\d+$/.test(inputAmount)) {
  if (Number(inputAmount) < 1) {
    setError('Enter amount greater than 0');
  } else {
    setError(null);   
}
}
else{
  setError('Enter a number');
}
console.log(" session value", getValue())
};


 const walletTotal = (wallet:[]) => {
  const total = wallet.reduce((accumulator, currentValue) => 
    (currentValue['walletType'] ==='CREDIT' ? accumulator + currentValue['walletAmount'] : accumulator - currentValue['walletAmount']), 0);
  console.log("total", total)
  setWalletBalance(total);
};
 
 const getCommissionDetails = (walletRow:{})=>{
  console.log("walletRow", walletRow)
  setWalletRow(walletRow)
  setOpenDetail(true)
 }

 const getCommissions = async () => {
  let walletResponse :  shipmentOrderType = {}
  await axios.get(DEFAULTS.API_URL + 'agent/commission',
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      //walletResponse = response.data
      setWallet(sortByDate(response.data,'walletOrderDate'))
     console.log("wallet", response.data)
     walletTotal(response.data)
    }
  ) 
    
}

const getValue = () => {
  
  if (textFieldRef.current) {
    console.log("getValue", textFieldRef.current.value)
    return Number(textFieldRef.current.value);
  }
  return 0;
};

  

  useEffect(() => {
    getCommissions()

  },[]);
  const profile: {} = shipment
  console.log("store", profile)

  

  const formikCommission = useFormik({
    initialValues: {
      commission: 0,
      commissionName:ADMINDETAILS?.userProfileName,
      bankDetails :{
      bankName : ADMINDETAILS?.bankName ,
      bankingName: ADMINDETAILS?.bankingName ,
      bankAccNo: ADMINDETAILS?.bankAccNo,
      bankRoute : ADMINDETAILS?.bankRoute,
      bankSwiftCode: ADMINDETAILS?.bankSwiftCode
      }
    },
    validationSchema: yup.object({
      commission: yup
        .number()
        .min(10, 'Commission amount must be at least USD50')
        .max(
          parseFloat(Number(ADMINDETAILS?.commissionbalance).toFixed(2)),
          `Commission amount cannot exceed your commission balance of ${Number(ADMINDETAILS?.commissionbalance).toFixed(2)}`
        )
        .required('Please enter a commission amount'),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      axios
        .post(`${DEFAULTS.API_URL}agent/commission`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Handle success response
          setFormError(null); // Clear any previous error
          formikCommission.resetForm(); // Reset the form
          // Optionally handle additional success logic, e.g., notifications
          getCommissions()
        })
        .catch((error) => {
          // Handle error response
          setFormError(
            error.response?.data?.message || 'An unexpected error occurred.'
          );
        });
    },
  });
  


    return (
<>
<Heading icon={<MonetizationOnIcon color="secondary" />} text="My Commission" />
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>
              <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Grid container spacing={2}>
      {/* First Column */}
      <Grid item xs={8}>
        <Typography component="p" variant="h4">
          ${Number(ADMINDETAILS?.commissionbalance).toFixed(2)}
        </Typography>
      </Grid>

      {/* Second Column */}
      <Grid item xs={4}>
      <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20, marginLeft: 20}}
                   // disabled={(parseFloat(Number(ADMINDETAILS?.commissionbalance).toFixed(2)) < 20) ? true : false}
onClick={()=> {
  setRedeem(true)
  formikCommission.resetForm()
}}>
                    Redeem Commission
                  </Button>
      </Grid>
    </Grid>
  </Paper>
      </Grid>

     {redeem && <Grid item xs={12} md={12} lg={12}>
              <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Grid container spacing={2}>
      {/* First Column */}
      <Grid item xs={6} component="form" onSubmit={formikCommission.handleSubmit}>
  <Typography variant="h6" gutterBottom>
    Enter commission amount to redeem
  </Typography>
  
  <TextField
    margin="normal"
    name="commission"
    label="Commission Amount"
    type="number"
    id="commission"
    size="small"
    value={formikCommission.values.commission}
    onChange={formikCommission.handleChange}
    onBlur={formikCommission.handleBlur}
    error={formikCommission.touched.commission && Boolean(formikCommission.errors.commission)}
    helperText={formikCommission.touched.commission && formikCommission.errors.commission}
  />
  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', marginTop: '20px' }}>
    <Button
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      style={{ backgroundColor: '#eb4034' }}
      type="submit"
      onClick={()=>console.log('Errors:', formikCommission.errors)}
    >
      Request
    </Button>
    <Button
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      style={{ marginLeft: 20 }}
      onClick={() => setRedeem(false)}
    >
      Cancel
    </Button>
  </div>
</Grid>
      <Grid item xs={6}>

      The commission will be transferred to the bank account listed below. Please update your bank account details if there are any changes<br/><br/>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', fontSize: '0.9em' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ fontWeight: 'bold', width: '150px' }}>Bank Name:</span>
    <span>{ADMINDETAILS?.bankName}</span>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ fontWeight: 'bold', width: '150px' }}>Account Name:</span>
    <span>{ADMINDETAILS?.bankingName}</span>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ fontWeight: 'bold', width: '150px' }}>Account Number:</span>
    <span>{ADMINDETAILS?.bankAccNo}</span>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ fontWeight: 'bold', width: '150px' }}>Routing Code:</span>
    <span>{ADMINDETAILS?.bankRoute}</span>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ fontWeight: 'bold', width: '150px' }}>Swift Code:</span>
    <span>{ADMINDETAILS?.bankSwiftCode}</span>
  </div>
</div>

      </Grid>
      </Grid>
      </Paper>
      </Grid>
}

      <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    
                  }}
                >
                  <React.Fragment>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {(wallet.length > 0) ? (
        <Table size="small">
          <TableHead>
          <TableRow>
          <TableCell>Order</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Credit</TableCell>
            <TableCell>Debit</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(wallet?.map((row: any, idx: any) =>  (
             <>
            <TableRow key={row['_id']}>
              <TableCell>{row['commissionid']}</TableCell>
              <TableCell>{convertFirestoreTimestampToDate(row['createdate'])}</TableCell>

              <TableCell style={{color:'green'}}>{(row['commissionType']==='CREDIT')? `+$${row['commission']}`:''}</TableCell>
              <TableCell style={{color:'red'}}>{(row['commissionType']==='DEBIT')? `-$${row['commission']}`:''}</TableCell>
              <TableCell>{row['commissionStatus']}</TableCell>
              <TableCell><Button type="button" variant="text" onClick={()=>getCommissionDetails(row)}>Details</Button></TableCell>
            </TableRow>
          
            </>
          
    )))}
    </TableBody>
    </Table>) : <> No records found</>}

            </Typography>
      


    </React.Fragment>
                </Paper>
              </Grid>
              
             

            </Grid>
      
            

<Modal
  open={openDetail}
  onClose={handleCloseDetail}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"  
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     Transaction Details
    </Typography>
    <hr/>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <p> Transaction Id : {walletRow?.commissionid}</p>
    {/* <p> Date  : {convertFirestoreTimestampToDate(walletRow?.createdate)}</p> */}
    <p> Amount : ${walletRow?.commission}</p>
    <p> Transaction : {walletRow?.commissionType}</p>
    <p> Status : {walletRow?.commissionStatus}</p>
    {(walletRow?.walletType ==='CREDIT') && <p>  Order : {walletRow?.commissionOrder}</p>}
    <hr/>
    <span>Bank Name: {walletRow?.bankDetails?.bankName}</span><br/>
    <span>Payee Name : {walletRow?.bankDetails?.bankingName}</span><br/>
    <span>Account Number : {walletRow?.bankDetails?.bankAccNo}</span><br/>
    <span>Routing Number : {walletRow?.bankDetails?.bankRoute}</span><br/>
    <span>Swift Code : {walletRow?.bankDetails?.bankSwiftCode}</span><br/>
    <hr/>
    </Typography>
    <Button variant="contained"
      sx={{ mt: 3, mb: 2 }} onClick={()=>setOpenDetail(false)}>Close</Button>
  </Box>
</Modal>

</>
    );
  }

  

  export default MyCommission;