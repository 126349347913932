import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel,IconButton,Chip,Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import axios from 'axios';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Heading from '../../Common/Header/Heading';
import PageviewIcon from '@mui/icons-material/Pageview';
import Tooltip from '@mui/material/Tooltip';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;



function Orders() {
    const [shipOrders, setShipOrders] = useState([]);
    const [shipErrors, setShipError] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [dataFetched, setDataFetched] = useState(true)
    const token = localStorage.getItem('admin-token');
    const payStatus =['PENDING PAYMENT', 'DECLINED PAYMENT']
    const navigate = useNavigate();
    const columns = [
      {
        field: "orderId",
        headerName: "Order #",
        flex: 1,
        renderCell: (params: any) => (

       <a href={`/portal/order/view/${params.row.orderId}`}> {params.row.orderId}</a>
        )
      },
      {
        field: "orderStatus",
        headerName: "Status",
        flex: 2,
        renderCell: (params: any) => (
          <Chip
    label={params.row.orderStatus}
    size="small"
    sx={{
      borderRadius: '50px',
      backgroundColor: (() => {
        switch (params.row.orderStatus) {
          case 'COMPLETE':
            return 'lightgreen';
          case 'NEW':
            return 'orange';
            case 'IN PROGRESS':
            return 'mediumgreen';
          case 'CANCELLED':
            return 'lightcoral';
          default:
            return 'lightgray'; // Default background color for other statuses
        }
      })(),
      
      fontWeight: 'bold',
    }}
  />
  
          
        ),
      },
      {
        field: "orderDate",
        headerName: "Date",
        flex: 2,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          convertFirestoreTimestampToDate(params.row.orderDate)
        )
      },
      {
        field: "orderPaymentId",
        headerName: "Payment Id",
        flex: 2,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row?.orderPaymentDetails?.id ? params.row?.orderPaymentDetails?.id : '-'
        )
      },

      {
        field: "orderPaymentStatus",
        headerName: "Payment Status",
        flex: 3,
        renderCell: (params: any) => (
          <Chip
    label={params.row.orderPaymentStatus}
    size="small"
    sx={{
      borderRadius: '50px',
      backgroundColor: (() => {
        switch (params.row.orderPaymentStatus) {
          case 'PAYMENT COMPLETE':
            return 'green';
          case 'PENDING PAYMENT':
            return 'orange';
            case 'PAYMENT FAILED':
            return 'red';
          case 'CANCELLED':
            return 'lightcoral';
          default:
            return 'lightgray'; // Default background color for other statuses
        }
      })(),
      
      fontWeight: 'bold',
    }}
  />
  
          
        ),
      },
      
      {
        field: "quoteId",
        headerName: "Quote #",
        flex: 1,
        sortable: true,
        filter:false,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params: any) => (
          <Tooltip title="View Order Details">
    <IconButton onClick={() => navigate(`/portal/order/view/${params.row.orderId}`)}>
      <PageviewIcon  />
    </IconButton>
  </Tooltip>
          
        ),
      },
      
    ];
  



      const getOrders = async () => {
        setLoading(true)
        await axios.get(DEFAULTS.API_URL+'orders',{headers: {
          'Authorization': `Bearer ${token}`
        }})
        
           .then(
             response => {
              const rowsWithIds = response.data.map((row: any, index: any) => ({
                ...row,
                id: index + 1, // You can use any unique identifier here
              }));
              setShipOrders(rowsWithIds.sort((a: any, b: any) => b.orderId - a.orderId));
              setLoading(false);
                            
             }
              // response.data.rate_response.rates
             
           )
       }
       
       useEffect(() => {
         // Update the document title using the browser API
         //expensesListResp();
         getOrders();
       },[]);

       const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
          { field: 'firstname', operator: 'contains', value: '' },
          { field: 'lastname', operator: 'contains', value: '' },
          { field: 'username', operator: 'contains', value: '' },
          { field: 'company', operator: 'contains', value: '' },
           // Correct property
        ],
      });
    
      const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterModel({
          items: [
            { field: 'firstname', operator: 'contains', value: e.target.value },
            { field: 'lastname', operator: 'contains', value: e.target.value },
            { field: 'username', operator: 'contains', value: e.target.value },
            { field: 'company', operator: 'contains', value: e.target.value }, // Corrected property name
          ],
        });
      };

    return (
      <>
        
   
        <Heading icon={<ShoppingCartIcon color="secondary" />} text="Orders" />
            <Card variant="outlined">
                <CardContent>

                <Box sx={{ marginBottom: 2 }}>
        <TextField
          label="Filter by Name, Email, Company Name"
          variant="outlined"
          fullWidth
          onChange={handleFilterChange}
        />
      </Box>

                <DataGrid
        rows={shipOrders}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.orderId}
        loading={loading}       
        style={{marginBottom:10}}
        filterModel={filterModel} // Apply the filter model
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
      />

</CardContent>
</Card>


      </>
    );
  }


  
  export default Orders;